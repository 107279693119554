<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'User Check'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 48 42"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="m27.418 40.8203c-2.7539 0-5.3907-.5859-7.793-1.6406 1.875-2.207 2.9883-5.0391 2.9883-8.0859 0-.9961-.1172-2.0118-.3516-2.9493 1.543-.3711 3.2617-.6054 5.1367-.6054 5.8985 0 10.4297 2.1875 12.3438 4.4726 2.6758-2.9101 4.2773-6.8164 4.2773-11.1133 0-9.2187-7.4218-16.60152-16.6211-16.60152-8.4375 0-15.3125 6.17192-16.3867 14.27732-1.03514-.1172-2.42186-.039-3.37889.1367 1.09375-9.90231 9.58989-17.734338 19.76559-17.734338 10.8789 0 19.9414 9.023438 19.9414 19.921838 0 10.8789-9.0429 19.9219-19.9218 19.9219zm-.0196-16.6015c-3.75-.0391-6.6796-3.1836-6.6992-7.3633-.039-3.9453 2.9492-7.20706 6.6992-7.20706 3.7305 0 6.6993 3.26176 6.6993 7.20706 0 4.1992-2.9493 7.4023-6.6993 7.3633zm-17.3828 16.8164c-5.42966 0-9.92185-4.4532-9.92185-9.9219 0-5.4297 4.49219-9.9219 9.92185-9.9219 5.4492 0 9.9219 4.4727 9.9219 9.9219 0 5.4297-4.5508 9.9219-9.9219 9.9219zm-1.30857-4.5704c.42969 0 .82031-.1757 1.03516-.4687l5.66401-7.8711c.1758-.2539.2735-.5273.2735-.7422 0-.6836-.5664-1.2109-1.2305-1.2109-.4492 0-.8008.2148-1.0351.5469l-4.74613 6.6015-2.40235-2.5976c-.21484-.2149-.52734-.4102-.9375-.4102-.68359 0-1.23046.5273-1.23046 1.2305 0 .2734.09765.625.33203.8789l3.30078 3.6328c.25391.2734.66406.4101.97656.4101z"
    fill={fillColor}
  />
</svg>
