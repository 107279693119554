<script>
  import { twMerge } from 'tailwind-merge'
  import { colors, baseSize, sizes, baseStyle } from './base-settings.js'

  export let size = baseSize
  export let role = 'img'
  export let color = 'currentColor'

  export let ariaLabel = 'Card'

  $: fillColor = colors[color] || color
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  fill={fillColor}
  {...$$restProps}
  class={twMerge(baseStyle, sizes[size ?? 'md'], $$props.class)}
  {role}
  aria-label={ariaLabel}
  tabindex="-1"
  viewBox="0 0 47 34"
  on:click
  on:keydown
  on:keyup
  on:focus
  on:blur
  on:mouseenter
  on:mouseleave
  on:mouseover
  on:mouseout
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <path
    d="M7.11328 33.5977C3.03125 33.5977 0.980469 31.5859 0.980469 27.5625V6.29297C0.980469 2.26953 3.03125 0.238281 7.11328 0.238281H40.7461C44.8477 0.238281 46.8789 2.26953 46.8789 6.29297V27.5625C46.8789 31.5859 44.8477 33.5977 40.7461 33.5977H7.11328ZM4.125 6.46875V8.01172H43.7344V6.46875C43.7344 4.4375 42.6211 3.38281 40.707 3.38281H7.15234C5.19922 3.38281 4.125 4.4375 4.125 6.46875ZM7.15234 30.4531H40.707C42.6211 30.4531 43.7344 29.418 43.7344 27.3867V12.4453H4.125V27.3867C4.125 29.418 5.19922 30.4531 7.15234 30.4531ZM9.75 26.7617C8.59766 26.7617 7.83594 25.9805 7.83594 24.8867V21.2734C7.83594 20.1602 8.59766 19.3984 9.75 19.3984H14.5352C15.6875 19.3984 16.4492 20.1602 16.4492 21.2734V24.8867C16.4492 25.9805 15.6875 26.7617 14.5352 26.7617H9.75Z"
    fill={fillColor}
  />
</svg>
